import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import LangLayout from "../../components/LangLayout"
import PageHeaderMedia from "../../components/PageHeaderMedia"
import SEO from "../../components/SEO"
import { useLocationData } from "../../hooks/locationHook"

const StyledContents = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 50px auto;
  .conSec {
    padding: 1.62em 0 3.62em;
    h2 {
      color: #3a3a3a;
      font-size: 1.62em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.62em;
      &:before {
        display: block;
        content: "";
        height: 1.32em;
        width: 8px;
        margin-right: 0.32em;
        border-radius: 1px;
        background: ${({ theme }) => theme.colors.primary.red};
      }
    }
    h3 {
      padding: 0.62em 0;
      font-weight: bold;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      &:after {
        display: block;
        content: "";
        height: 2px;
        border-radius: 2px;
        width: 38%;
        background: ${({ theme }) => theme.colors.primary.green};
        margin: 1.62em 0;
      }
    }
    p {
      width: 100%;
      text-align: center;
    }
  }
  .conSection {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    h2 {
      font-size: 1.62em;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 1.62em;
      &:before {
        display: block;
        content: "";
        width: 16%;
        height: 8px;
        border-radius: 3px;
        background: ${({ theme }) => theme.colors.primary.green};
        margin: 1.62em 0 0.62em;
      }
    }
    .gatsby-image-wrapper {
      width: 300px;
      margin: 30px auto;
    }
  }
  ${() =>
    media.sp(css`
      .gatsby-image-wrapper {
        width: 230px !important;
      }
    `)}
`

const LangCommit = ({ location }) => {
  const data = useStaticQuery(query)
  useLocationData(location.pathname)

  return (
    <LangLayout>
      <SEO>
        <title>Francois' Commitment｜Francois</title>
        <meta
          name="description"
          content='We pursue "healthy" and "great-tasting" bread making.1.Let&apos;s eliminate additives from the body!   2.It&apos;s smart to take in the good stuff!   3.We offer fresh tasty products.'
        />
      </SEO>
      <PageHeaderMedia
        fluid={data.zoo.commitHead.headerMedia.childImageSharp.fluid}
        pageTitles={data.zoo.commitHead.headerTitleEn}
        pageText={data.zoo.commitHead.subTitleEn}
      />
      <StyledContents>
        <div className="conSec">
          <h2>{data.zoo.enCommit.commitTitle}</h2>
          <h3>{data.zoo.enCommit.commitBig}</h3>
          <p>{data.zoo.enCommit.commitSmall}</p>
        </div>
        <div className="conSection">
          <h2>{data.zoo.enCommit.commitTitle1}</h2>
          <p>{data.zoo.enCommit.commitContents1}</p>
          <Image
            fluid={data.zoo.enCommit.commitMedia1.childImageSharp.fluid}
            alt="francoisEn"
          />
        </div>
        <div className="conSection">
          <h2>{data.zoo.enCommit.commitTitle2}</h2>
          <p>{data.zoo.enCommit.commitContents2}</p>
          <Image
            fluid={data.zoo.enCommit.commitMedia2.childImageSharp.fluid}
            alt="francoisEn"
          />
        </div>
        <div className="conSection">
          <h2>{data.zoo.enCommit.commitTitle3}</h2>
          <p>{data.zoo.enCommit.commitContents3}</p>
          <Image
            fluid={data.zoo.enCommit.commitMedia3.childImageSharp.fluid}
            alt="francoisEn"
          />
        </div>
      </StyledContents>
    </LangLayout>
  )
}

export const query = graphql`
  {
    zoo: strapiLangCommitment {
      commitHead {
        headerTitleCn
        headerTitleEn
        mediaAlt
        headerMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      enCommit {
        commitTitle
        commitBig
        commitSmall
        commitTitle1
        commitTitle2
        commitTitle3
        commitContents1
        commitContents2
        commitContents3
        commitMedia1 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        commitMedia2 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        commitMedia3 {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default LangCommit
